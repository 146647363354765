<template>
  <div class="caseDetailWrap">
    <div class="caseDetail" v-loading.fullscreen="loading">
      <div class="header">
        <h1 class="title" v-if="detail.title">{{ detail.title }}</h1>
        <p class="case" style='padding-top:10px;'>案列：{{ detail.works_name }}</p>
        <p class="brand">品牌：{{ detail.bra }}</p>
        <p class="label" v-for="(item, index) in detail.label" :key="index">
          <span>{{ item }}</span>
          <span class="line" v-if="index < detail.label.length - 1">/</span>
        </p>
        <p class="time">{{ detail.createTimeText }} 发布</p>
      </div>
      <div class="content" v-html="detail.detalis"></div>
    </div>
  </div>
</template>

<script>
import { caseDetail } from '@/api/common'
import { timeFormat } from '@/utils/index'
import { unescape } from 'html-escaper'
export default {
  data() {
    return {
      id: '',
      detail: {},

      loading: true,
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getCaseDetail()
  },
  methods: {
    async getCaseDetail() {
      try {
        let { data } = await caseDetail({
          works_id: this.id,
        })
        setTimeout(() => {
          this.loading = false
        }, 500)
        data.createTimeText = timeFormat(data.create_time * 1000, 'YYYY/MM/DD')
        data.detalis = unescape(data.detalis)
        this.detail = data
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.caseDetailWrap {
  min-width: 1160px;
  padding: 70px 0;
  background: #f4f5f9;
}
.caseDetail {
  width: 1160px;
  // padding: 50px 0;
  margin: 0 auto;
  .header {
    text-align: left;
    background: #fff;
    padding: 20px;
    .title {
      font-size: 34px;
      font-weight: bold;
      color: #383838;
      line-height: 44px;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -9px;
        width: 61px;
        height: 6px;
        background-color: #f5c02f;
      }
    }
    .case,
    .brand {
      font-size: 21px;
      color: #373737;
      line-height: 27px;
    }
    .case {
      // margin-top: 43px;
    }
    .label,
    .time {
      font-size: 16px;
      color: #828282;
      line-height: 32px;
    }
    .label {
      margin-top: 27px;
      display: inline-block;
      .line {
        padding: 0 10px;
      }
    }
  }
  .content {
    background: #fff;
    padding: 20px;
    margin-top: 55px;
    & /deep/ p,
    div,
    span {
      text-align: left;
      color: #727272;
      font-size: 22px;
      line-height: 32px;
    }
    & /deep/ p img {
      display: inline-block;
    }
  }
}
</style>
